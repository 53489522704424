import './CashiersWithAgreements.scss'

import { Shortcut } from '@lk/lk-design-system'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import costOfCashiersWithAgreement from '../../../../images/Components/OfficesMap/costOfCashiersWithAgreement.svg'
import * as SanitySchema from '../../../../lib/types/sanity-schema'

export type CashiersProps = {
  cashiersWithAgreements?: { node: SanitySchema.CashiersWithAgreements }[]
}

const CashiersWithAgreements: FC<CashiersProps> = ({ cashiersWithAgreements }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="bg-berenjena-claro-4 cashier-info">
        <div className="container-price">
          <img
            className="price-image"
            src={costOfCashiersWithAgreement}
            alt={t('cashiers.cashiersWithAgreements.title')}
          />
          <div className="centered-price strong">{t('cashiers.cashiersWithAgreements.cost')}</div>
        </div>
        <div className="cashier-text">
          <div className="strong">{t('cashiers.cashiersWithAgreements.title')}</div>
          <div>{t('cashiers.cashiersWithAgreements.description')}</div>
        </div>
      </div>
      <div className="row">
        {cashiersWithAgreements
          ?.sort((a, b) => a.node.bankName.localeCompare(b.node.bankName))
          .map(({ node }) => (
            <div
              className="col-lg-4 col-md-6 col-sm-12 cashiers-list"
              key={`cashier-${node.bankName.replace(' ', '')}`}
            >
              <Shortcut
                type="tile"
                icon={node.logo.asset.url}
                cta={node.bankUrl ? node.bankUrl : '#'}
                iconAlt={node.bankName}
              />
            </div>
          ))}
      </div>
    </>
  )
}

export default CashiersWithAgreements

CashiersWithAgreements.defaultProps = {
  cashiersWithAgreements: [],
}
